<template>
  <div>
    <h1 class="mt-3">Job Progress Tracking</h1>
    <b-button size="sm" @click="toggle()" variant="link">{{buttonLabel}} Selector</b-button>
    <b-container v-if="showPicker">      
      <b-row>
        <b-col>
          <b-alert show variant="danger" v-if=errorMsg>{{ errorMsg }}
            <div v-if=(errorShowRefresh)>Please refresh this page and try again, or contact an administrator if you continue to see this error.</div>
          </b-alert>
        </b-col>
      </b-row>
      <b-row class="mt-3 mb-3">
        <b-col class="d-flex flex-column">
          <p>Select a results folder to display job tracking for related jobs</p>
          <b-list-group class="fileslist">
            <b-list-group-item button class="d-flex justify-content-between align-items-center"
              v-for="folder in orderedFolders" v-bind:key="folder.name"
              :active="folder===selectedFolder"
              @click="folderSelected(folder)"
              >
              <b-col><small>{{ folder.displayName }}</small></b-col>
              <b-col><small>{{ new Date(folder.timestamp).toLocaleString() }}</small></b-col>
            </b-list-group-item>
          </b-list-group>
        </b-col>
      </b-row>
      <!--<folder-picker :folders="folders"
        @select-folder="folderSelected">
      </folder-picker> -->
    </b-container>
    
    <b-container class="mt-3">

      <h4 v-if="this.folderName != null">{{ this.folderName }}</h4>

      <div class="mt-4" v-if="jsonData != null">
        <b-table-simple hover small caption-top responsive>
          <b-thead head-variant="dark">
            <b-tr>
              <b-th>Type</b-th>
              <b-th>Sample ID</b-th>
              <b-th>Status</b-th>
              <b-th>Job ID</b-th>
              <b-th>Submitted</b-th>
              <b-th>Started</b-th>
              <b-th>Ended</b-th>
              <b-th>Runtime</b-th>
              <b-th>Summary</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr v-for="job in jsonData.jobs" v-bind:key="job.id">
              <b-td>{{ job.type }}</b-td>
              <b-td>{{ job.name }}</b-td>
              <b-td nowrap>{{ formatJobStatus(job.status) }}
                <i v-if="job.status === 1" class="mdi mdi-clock-outline" /> 
                <i v-if="job.status === 2" class="mdi mdi-spin mdi-reload" /> 
                <i v-if="job.status === 3" class="mdi mdi-skull-outline" /> 
                <i v-if="job.status === 4" class="mdi mdi-check" /> 
              </b-td>
              <b-td>{{ job.id }}</b-td>
              <b-td>{{ formatTimestamp(job.timeSubmitted) }}</b-td>
              <b-td>{{ formatTimestamp(job.timeStarted) }}</b-td>
              <b-td>{{ formatTimestamp(job.timeEnded) }}</b-td>
              <b-td>{{ formatTimeTaken(job.timeTaken) }}</b-td>
              <b-td>
                <div v-if="job.status === 4">
                  <a target="_blank" :href="createLink(job)">View</a>
                </div>
              </b-td>
            </b-tr> 
          </b-tbody>
        </b-table-simple>
        
        <b-table-simple v-if="this.resubmitData != null">
          <pre>{{createParamTable()}}</pre>
          <b-button variant="primary" @click="$router.push({ name: resubmitData[0].jobType, params: { resubmitData: resubmitData[1] }})">Resubmit Job</b-button>
        </b-table-simple>

      </div>
      
    </b-container>
  </div>
</template>

<script>

import axios from 'axios'
import { mapGetters } from 'vuex'
//import FolderPicker from '../components/FolderPicker.vue'

export default {
  name: "tracking",
  /*components: {
    "folder-picker": FolderPicker,
  },*/
  data() {
    return {
      // Error message that we want to display (if any)
      errorMsg: null,
      // Should the UI show a 'please refresh' msg when showing the error
      errorShowRefresh: true,
      data: null,
      folders: null,
      selectedFolder: {
        name: null,
        files: []
      },
      interval: null,
      jsonData: null,
      jobRunning: null,
      folderName: null,
      showPicker: true,
      buttonLabel: "Hide",
      folderID: null,
      resubmitData: null,
      showResubmit: false
    }
  },

computed: {
    ...mapGetters({token: "token"}),
    orderedFolders: function () {
      if (!this.folders) {
      return []
      } else {
        const foldersCopy = this.folders.concat()
        return foldersCopy.sort((a, b) => {
          return b.timestamp - a.timestamp
        })
      }
    }
  },

  mounted() {
    axios.get("/folders?stage=tracking", 
      {
        params: { token: this.token },
        headers: {"Content-Type": "application/json"}
      }).then(
        function(response) {
          this.folders = response.data;
          this.onLoad();
        }.bind(this)
      )
      .catch(error => {
        console.log(error)
        this.errorMsg = "Unable to retrieve a list of folders.";
      });
  },

  methods: {
    onLoad: function() {
      this.folderID = this.$route.params.folderID;
      if(this.folderID !== undefined && this.folderID !== null)
      {
        this.folders.forEach(folder => {
          if((folder.id.localeCompare(this.folderID) == 0))
          {
            this.folderSelected(folder);
            this.toggle();
          }
        });
      }
    },

    toggle: function() {
      this.showPicker = !this.showPicker;
      if(this.showPicker)
      {
        this.buttonLabel = "Hide ";
      }
      else
      {
        this.buttonLabel = "Show ";
      }
    },

    folderSelected: function(folder) {
      this.selectedFolder = folder;
      this.jobRunning = true;
      this.checkProgress();
      this.interval = setInterval(this.checkProgress, 5000);
      this.showParams();
    },

    checkProgress: function() {
      axios.get("/shortread/tracking/" + this.selectedFolder.id,
      {
        params: { token: this.token },
        headers: {"Content-Type": "application/json"}
      }).then(
        function(response) {
          this.jsonData = response.data;
          
          if (this.jsonData.isRunning == false)
          {
            this.jobRunning = false;
            clearInterval(this.interval);
          }
        }.bind(this)
        )
        .catch(error => {
          clearInterval(this.interval);
          console.log(error);
        });
    },

    formatJobStatus: function(status) {
      switch (status)
      {
        case 1: return "Queued";
        case 2: return "Running";
        case 3: return "Failed";
        case 4: return "Finished";
      }

      return "";
    },

    formatTimestamp: function(timestamp) {
      if (timestamp !== 0)
        return new Date(timestamp).toLocaleDateString()
          + " " + new Date(timestamp).toLocaleTimeString();
      else
        return "";
    },

    formatTimeTaken: function(time) {
      if (time === 0)
        return "";
      else
      {
        // TODO: Won't work for jobs longer than 24 hours
        return new Date(time).toISOString().slice(11, -5);
      }
    },

    createLink: function(job) {
      let jobType = job.type;
      if(jobType.includes(" "))
        jobType = jobType.substr(0, jobType.indexOf(" "))
      
      let path = process.env.VUE_APP_BASE_URL + "rtdbox-servlet/shortread/results/" + this.selectedFolder.id + "/" + jobType + "/" + job.id + "/" + this.token;

      return path;
    },

    async showParams() {
      axios.get("/shortread/resubmit", 
        {
          params: {
            token: this.token,
            folderID: this.selectedFolder.id
          },
          headers: {"Content-Type": "application/json"}
        }).then(
          function(response) {
            this.resubmitData = response.data;
            this.showResubmit = true;
          }.bind(this)
        )
        .catch(error => {
          console.log(error)
        });
      },

    createParamTable: function() {
      var output = "";
      const jobData = this.resubmitData[1];
      if(jobData.quality != null && jobData.quality != undefined)
      {
        output += `Quality Filter: ${jobData.quality}\n`;
      }
      if(jobData.length != null && jobData.length != undefined)
      {
        output += `Length Filter: ${jobData.length}\n`;
      }
       if(jobData.minIntrons != null && jobData.minIntrons != undefined)
      {
        output += `Minimum Intron Size: ${jobData.minIntrons}\n`;
      }
       if(jobData.maxIntrons != null && jobData.maxIntrons != undefined)
      {
        output += `Maximum Intron Size: ${jobData.maxIntrons}\n`;
      }
       if(jobData.mismatches != null && jobData.mismatches != undefined)
      {
        output += `Permitted Mapping Mismatches: ${jobData.mismatches}\n`;
      }
      if(jobData.libType != null && jobData.libType != undefined)
      {
        output += `Library Type: ${jobData.libType}\n`;
      }
      if(jobData.SJReadsCutOff != null && jobData.SJReadsCutOff != undefined)
      {
        output += `Minimum number of mapped reads to support a splice junction: ${jobData.SJReadsCutOff}\n`;
      }
      if(jobData.SJReadsSample != null && jobData.SJReadsSample != undefined)
      {
        output += `Minimum number of samples to support the splice junction: ${jobData.SJReadsSample}\n`;
      }
      if(jobData.TPMCutOff != null && jobData.TPMCutOff != undefined)
      {
        output += `Minimum TPM abundance for expressed transcripts: ${jobData.TPMCutOff}\n`;
      }
      if(jobData.TPMSample != null && jobData.TPMSample != undefined)
      {
        output += `Minimum number of samples for expressed transcript consideration: ${jobData.TPMSample}\n`;
      }
      if(jobData.fragmentLen != null && jobData.fragmentLen != undefined)
      {
        output += `Fragment transcript length: ${jobData.fragmentLen}\n`;
      }
      if(jobData.antiSenseLen != null && jobData.antiSenseLen != undefined)
      {
        output += `Monoexonic-antisense fragment transcript length: ${jobData.antiSenseLen}\n`;
      }
      if(jobData.prefix != null && jobData.prefix != undefined)
      {
        output += `ID prefix: ${jobData.prefix}\n`;
      }
      if(jobData.maxIntron != null && jobData.maxIntron != undefined)
      {
        output += `Maximum Intron Size: ${jobData.maxIntron}\n`;
      }
      if(jobData.SJError != null && jobData.SJError != undefined)
      {
        output += `Splice junction error region: ${jobData.SJError}\n`;
      }
      if(jobData.statsCutOff != null && jobData.statsCutOff != undefined)
      {
        output += `TSS/TES test statistics cut-off: ${jobData.statsCutOff}\n`;
      }
      if(jobData.statsType != null && jobData.statsType != undefined)
      {
        output += `TSS/TES test statistics type: ${jobData.statsType}\n`;
      }
      if(jobData.minRead != null && jobData.minRead != undefined)
      {
        output += `Minimum Read Count: ${jobData.minRead}\n`;
      }
      if(jobData.binSize != null && jobData.binSize != undefined)
      {
        output += `Bin Size to Count Reads: ${jobData.binSize}\n`;
      }
      if(jobData.TSSWobbling != null && jobData.TSSWobbling != undefined)
      {
        output += `TSS Wobbling Region: ${jobData.TSSWobbling}\n`;
      }
      if(jobData.TESWobbling != null && jobData.TESWobbling != undefined)
      {
        output += `TES Wobbling Region: ${jobData.TESWobbling}\n`;
      }
      if(jobData.TSSCollapse != null && jobData.TSSCollapse != undefined)
      {
        output += `TSS Collapse Threshold: ${jobData.TSSCollapse}\n`;
      }
      if(jobData.TESCollapse != null && jobData.TESCollapse != undefined)
      {
        output += `TES Collapse Threshold: ${jobData.TESCollapse}\n`;
      }
      if(jobData.geneOverlap != null && jobData.geneOverlap != undefined)
      {
        output += `Chimeric gene overlap percent: ${jobData.geneOverlap}\n`;
      }
      if(jobData.orf != null && jobData.orf != undefined)
      {
        output += `Minimum Amino-Acid Number of ORF: ${jobData.orf}\n`;
      }
      if(jobData.peptide != null && jobData.peptide != undefined)
      {
        output += `Minimum amino-acid number of peptide: ${jobData.peptide}\n`;
      }
      if(jobData.ptc != null && jobData.ptc != undefined)
      {
        output += `CDS length percent of PTC: ${jobData.ptc}\n`;
      }
      return output;
    }
  },

  beforeDestroy() {
    clearInterval(this.interval)
  },
}
</script>

<style scoped>
.error {
  color: #ff0000;
}

.fileslist {
  border: solid 1px lightgrey;
  min-height: 222px;
  max-height: 222px;
  overflow-y: auto;
}
</style>